import Rights from "common/services/Rights";

export const optionsLOmode = (tabId) => [
  {
    type: "SelectionGroup",
    id: "select-option",
    multi: false,
    closeOnClick: true,
    items: [
      {
        id: "/search-form",
        label: "Search Form",
      },
      {
        id: "/search-results",
        label: "Search Results",
      },
      {
        id: "/lock-summary",
        label: "Lock Summary",
        disabled: tabId !== "/lock-summary",
      },
    ],
  },
];

const sellSideNavigation = [
  { type: "separator", title: "Sell Side" },
  {
    type: "SelectionGroup",
    id: "epps-menu-view-id3",
    multi: false,
    closeOnClick: false,
    items: [
      {
        id: "/search-form/",
        label: "Search Form",
        disabled: true,
      },
      {
        id: "/search-result/",
        label: "Search Results",
        disabled: true,
      },
      {
        id: "/lock-summary/",
        label: "Pricing",
        disabled: true,
      },
    ],
  },
];

const buysideNavigation = (disablePricingPage, disableRevised) => [
  { type: "separator", title: "Buy Side" },
  {
    type: "SelectionGroup",
    id: "epps-menu-view-id",
    multi: false,
    closeOnClick: true,
    items: [
      {
        id: "/search-form",
        label: "Search Form",
        disabled: disableRevised,
      },
      {
        id: "/search-results",
        label: "Search Results",
        disabled: disableRevised,
      },
      {
        id: "/lock-summary",
        label: "Pricing",
        disabled: disablePricingPage,
      },
    ],
  },
];

export const optionsSecondaryMode = (disablePricingPage, disableRevised) => [
  ...(Rights.accessBuyside
    ? buysideNavigation(disablePricingPage, disableRevised)
    : []),
  ...(Rights.accessBuyside && Rights.accessSellside
    ? [{ id: "divider", type: "separator" }]
    : []),
  ...(Rights.accessSellside ? sellSideNavigation : []),
];
