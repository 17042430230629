import React, { useState, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { isEmpty } from "utils/shared";
import {
  cancelLockAction,
  denyLockRequestAction,
  denyActiveLockAction,
  updateAndConfirmAction,
} from "data/lockConfirm/actionCreators";
import FooterButtons from "components/FooterButtons";
import {
  useCompareDataSelector,
  useLoanDataFieldSelector,
  useOriginalLoanInformationSelector,
} from "data/useSelectors";
import Common from "common/services/Common";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import Session, {
  ALL_SUMMARY_PARAMS,
  IS_VALIDATE,
  ORIGIN_LOCKID,
} from "services/Session";
import CommentModal from "../CommentModal";
import UpdateLockDisabledTooltip from "./components/UpdateLockDisabledTooltip";
import { getFooterButtons } from "./helpers";

const FooterActionButtons = () => {
  const dispatch = useDispatch();
  const [denyLockModal, setDenyLockModal] = useState(false);
  const [cancelLockModal, setCancelLockModal] = useState(false);
  const [denyActiveLockModal, setDenyActiveLockModal] = useState(false);
  const [openLockAndConfirmModal, setOpenLockAndConfirmModal] = useState(false);
  const lockRequests = useOriginalLoanInformationSelector("lockRequests", []);
  const { changeCounter: pricingChangesCount = 0 } = useCompareDataSelector();
  const loanFieldChanges = useLoanDataFieldSelector(
    "additionalLockInfo.lockCompareResults",
    {}
  );
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges]
  );

  const isValidateSubmitButtonEnabled = useSelector(
    ({
      epps: { lockConfirm: { validateSubmitButtonEnabled = false } = {} } = {},
    }) => validateSubmitButtonEnabled,
    shallowEqual
  );

  const isUpdateLockButtonDisabled = useMemo(() => {
    const notNewProduct = isEmpty(Session.get(ALL_SUMMARY_PARAMS));
    return (
      Session.get(IS_VALIDATE) &&
      pricingChangesCount === 0 &&
      loanFieldChangesCount === 0 &&
      !isValidateSubmitButtonEnabled &&
      notNewProduct
    );
  }, [
    pricingChangesCount,
    loanFieldChangesCount,
    isValidateSubmitButtonEnabled,
  ]);

  const request = lockRequests.find(
    (req) => req.id === Session.get(ORIGIN_LOCKID)
  );

  const isActiveLock = lockRequests.some(
    ({ requestedStatus }) => requestedStatus === "Locked"
  );

  const buttonsProps = {
    deny: {
      show: request?.requestedStatus === "Requested",
      handler: () => setDenyLockModal(true),
    },
    denyActiveLock: {
      show: request?.requestedStatus !== "Requested" && isActiveLock,
      handler: () => setDenyActiveLockModal(true),
    },
    cancel: {
      show: request?.requestedStatus !== "Requested" && isActiveLock,
      handler: () => setCancelLockModal(true),
      disabled: isUpdateLockButtonDisabled,
    },
    lockConfirm: {
      show: true,
      handler: () =>
        dispatch(updateAndConfirmAction(setOpenLockAndConfirmModal)),
      disabled: isUpdateLockButtonDisabled,
    },
  };

  const buttons = getFooterButtons(buttonsProps);

  return (
    <>
      <CommentModal
        title="Deny Lock Request"
        show={denyLockModal}
        onHide={() => setDenyLockModal(false)}
        onConfirm={(comments) => dispatch(denyLockRequestAction(comments))}
      />
      <CommentModal
        title="Deny Lock"
        show={denyActiveLockModal}
        onHide={() => setDenyActiveLockModal(false)}
        onConfirm={(comments) => dispatch(denyActiveLockAction(comments))}
      />
      <CommentModal
        title="Cancel Lock"
        show={cancelLockModal}
        onHide={() => setCancelLockModal(false)}
        onConfirm={(comments) => dispatch(cancelLockAction(comments))}
      />
      <DSModal
        centered
        isOpen={openLockAndConfirmModal}
        modalSubType={MODAL_SUB_TYPE_V2.SUCCESS}
        modalTitle="Success"
        modalType={MODAL_TYPE_V2.DECISION}
        shouldCloseOnOverlayClick
        confirmLabel="Close ICE PPE"
        onClose={Common.closeApplication}
        onConfirm={Common.closeApplication}
        version={2}
      >
        <p>Pricing lock is confirmed.</p>
      </DSModal>
      <FooterButtons
        buttons={buttons}
        tooltip={
          <UpdateLockDisabledTooltip show={isUpdateLockButtonDisabled} />
        }
      />
    </>
  );
};

export default FooterActionButtons;
