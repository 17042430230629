import React, { useState } from "react";
import { string } from "prop-types";
import { DSPageHeaderV2 } from "@elliemae/ds-page-header";
import { ChevronSmallDown } from "@elliemae/ds-icons";
import { DSDropdownMenu } from "@elliemae/ds-dropdownmenu";
import { DSButtonV2 } from "@elliemae/ds-button";
import { DSBreadcrumb } from "@elliemae/ds-breadcrumb";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  useLoanDataFieldSelector,
  useSearchFormSelector,
} from "components/Form/utils/customHooks";
import { setRedirectTabAction } from "data/landing/actions";
import { searchFormActions } from "store/searchForm";
import Session, {
  ORIGIN_SOURCE,
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
  IS_SELLSIDE,
} from "services/Session";
import { useLockedProgramSelector } from "data/useSelectors";
import { isEmpty } from "utils/shared";
import HeaderToolbar from "./HeaderTooltip";
import HeaderModal from "./HeaderModal";
import HeaderTitle from "./HeaderTitle";
import { getMenuOptions } from "./utils/helpers";
import {
  LO_PAGE_TITLES,
  SEC_PAGE_TITLES,
  SEC_BUYSIDE_PAGE_TITLES,
  SEC_SELLSIDE_PAGE_TITLES,
} from "./utils/constants";

const HeaderMenu = ({ loanId, applicationName }) => {
  const dispatch = useDispatch();
  const tabId = useSelector(({ landing }) => landing.tabId, shallowEqual);
  const errorForm = useSearchFormSelector("errorForm").value;
  const [isOpen, setOpen] = useState(false);
  const isSmartClient = applicationName === "ENC";
  const isSecondary = Session.get(ORIGIN_SOURCE) === "secondarylock";
  const { status } = useLockedProgramSelector();
  const loanData = useLoanDataFieldSelector();
  const { requestAction, requestLockStatus, requestLockType } = loanData;
  const disableRevised =
    requestAction === 4 && requestLockStatus === 2 && requestLockType === 1;
  const disablePricingPage =
    status !== "eligible" && isEmpty(Session.get(ALL_SUMMARY_PARAMS));

  const tabIds = getMenuOptions(
    tabId,
    isSecondary,
    disablePricingPage,
    disableRevised
  );
  const searchFormId = isSecondary
    ? tabIds?.[1]?.items?.[0]?.id
    : tabIds?.[0]?.items?.[0]?.id;
  const searchResultsId = isSecondary
    ? tabIds?.[1]?.items?.[1]?.id
    : tabIds?.[0]?.items?.[1]?.id;
  const lockSummaryId = isSecondary
    ? tabIds?.[1]?.items?.[2]?.id
    : tabIds?.[0]?.items?.[2]?.id;
  const isBuySide = Session.get(IS_BUYSIDE);
  const isSellSide = Session.get(IS_SELLSIDE);
  let PAGE_TITLES;
  if (isBuySide) {
    PAGE_TITLES = SEC_BUYSIDE_PAGE_TITLES;
  } else if (isSellSide) {
    PAGE_TITLES = SEC_SELLSIDE_PAGE_TITLES;
  } else {
    PAGE_TITLES = LO_PAGE_TITLES;
  }

  const handleOpenModal = () => setOpen((prev) => !prev);

  const handleViewSelected = (newTabID) => {
    if (newTabID !== tabId) {
      // TODO maybe this validation should be inside an action
      if (newTabID === "/search-form") {
        // Validation Search Form
        const { errorFields, bannerIsOpen } = errorForm;
        if (errorFields.length > 0 && !bannerIsOpen) {
          dispatch(searchFormActions.setBannerIsOpen(true));
        } else if (errorFields.length === 0) {
          dispatch(setRedirectTabAction(newTabID));
          dispatch(searchFormActions.setBannerIsOpen(false));
        }
      } else {
        dispatch(setRedirectTabAction(newTabID));
      }
    }
  };

  const dropdownBtn = document.querySelector(
    '[data-testid="page-header-dropdown-trigger-component"]'
  );

  return (
    <>
      <HeaderModal {...{ isOpen, handleOpenModal }} />
      <DSPageHeaderV2
        pageTitle={
          <HeaderTitle
            {...{
              title: (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dropdownBtn?.focus();
                    dropdownBtn?.click();
                  }}
                >
                  {!isSellSide ? (
                    <>
                      {!isSecondary &&
                      (tabId === searchFormId || tabId === searchResultsId) ? (
                        <DSBreadcrumb trailingSlash={false}>
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchFormId]}
                            active={tabId === searchFormId}
                            onClick={() => {
                              handleViewSelected(searchFormId);
                            }}
                          />
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchResultsId]}
                            active={tabId === searchResultsId}
                            onClick={() => {
                              handleViewSelected(searchResultsId);
                            }}
                          />
                        </DSBreadcrumb>
                      ) : (
                        <DSBreadcrumb trailingSlash={false}>
                          {!disableRevised ? (
                            <DSBreadcrumb.Item
                              label={PAGE_TITLES[searchFormId]}
                              active={tabId === searchFormId}
                              onClick={() => {
                                handleViewSelected(searchFormId);
                              }}
                            />
                          ) : (
                            <></>
                          )}
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[searchResultsId]}
                            active={tabId === searchResultsId}
                            onClick={() => {
                              handleViewSelected(searchResultsId);
                            }}
                          />
                          <DSBreadcrumb.Item
                            label={PAGE_TITLES[lockSummaryId]}
                            active={tabId === lockSummaryId}
                            onClick={() => {
                              handleViewSelected(lockSummaryId);
                            }}
                          />
                        </DSBreadcrumb>
                      )}
                    </>
                  ) : (
                    SEC_PAGE_TITLES[tabId]
                  )}
                </span>
              ),
              isSmartClient,
            }}
          />
        }
        toolbar={
          <HeaderToolbar {...{ isSmartClient, handleOpenModal, loanId }} />
        }
        menu={
          isSellSide ? (
            <DSDropdownMenu
              onSelectMenuItem={({ id }) => handleViewSelected(id)}
              selection={{ "select-option": [tabId] }}
              options={getMenuOptions(
                tabId,
                isSecondary,
                disablePricingPage,
                disableRevised
              )}
              triggerComponent={
                <DSButtonV2
                  buttonType="icon"
                  data-testid="page-header-dropdown-trigger-component"
                >
                  <ChevronSmallDown />
                </DSButtonV2>
              }
            />
          ) : null
        }
      />
    </>
  );
};

HeaderMenu.defaultProps = {
  loanId: "",
  applicationName: "",
};

HeaderMenu.propTypes = {
  loanId: string,
  applicationName: string,
};

export default HeaderMenu;
