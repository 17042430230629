/* eslint-disable react/destructuring-assignment */
import { React } from "react";
import { objectOf, string } from "prop-types";
import Grid from "@elliemae/ds-grid";
import { useSelector, useDispatch } from "react-redux";
import Session, { ALL_SUMMARY_PARAMS } from "services/Session";
import { PATH_LOCK_SUMMARY } from "routes/constants";
import { setRedirectTabAction } from "data/landing/actions";
import Rights from "common/services/Rights";
import { LockUnlockedRateRequested as LockIcon } from "@elliemae/ds-icons";
import {
  StyledEligibleRowSelected,
  StyledEligibleProgramLinks,
  StyledProductNotesList,
} from "./styled";
import { dispPrice, dispApr, dispCurrencyMask, dispRate } from "../../helpers";

const DropdownDetails = (props) => {
  const cols = ["52%", "48%"];
  const program = props.row.original;
  const { rateDetails, setSlide, handleSelectRow, relockRequest } = program;
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const dispatch = useDispatch();
  const handleSelectProduct = (programID, rateDataID) => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: programID,
      rateDataId: rateDataID,
    });
    dispatch(setRedirectTabAction(PATH_LOCK_SUMMARY));
  };
  const percent = (val) => +(val * 100).toFixed(3);
  const rightAlign = { width: "100%", textAlign: "right", paddingRight: "3px" };
  const CellValue = ({ value }) => {
    return <span style={rightAlign}>{value}</span>;
  };
  CellValue.defaultProps = {
    value: "",
  };
  CellValue.propTypes = {
    value: string,
  };
  let parsedNotes = program.notes;
  parsedNotes = parsedNotes.replace(/{/g, "<");
  parsedNotes = parsedNotes.replace(/}/g, ">");
  parsedNotes = parsedNotes.replace(/\|/g, "<br/><li>");
  parsedNotes = `<ul><li>${parsedNotes}</ul>`;

  return (
    <>
      <Grid cols={cols}>
        <Grid style={{ paddingLeft: 60 }}>
          <div>
            {program.options !== "" && (
              <p style={{ paddingTop: 10, marginTop: 10 }}>
                <span style={{ fontWeight: "bold" }}>Supported Options: </span>
                {program.options}
              </p>
            )}
            {program.nonOptions !== "" && (
              <p
                style={{
                  paddingTop: program.options !== "" ? 0 : 10,
                  marginTop: program.options !== "" ? 0 : 10,
                }}
              >
                <span style={{ color: "red", fontWeight: "bold" }}>
                  Not Supported Options:{" "}
                </span>
                {program.nonOptions}
              </p>
            )}
            <p>
              <a
                href="https://help.icemortgagetechnology.com/documentation/EPPS-Web/Content/LTVCLTV.htm"
                target="_blank"
                rel="noreferrer"
              >
                LTV / CLTV / HCLTV
              </a>
              <span style={{ paddingLeft: 10, fontWeight: "bold" }}>
                {percent(program.ltv)} / {percent(program.cltv)} /{" "}
                {percent(program.hcltv)}
              </span>
            </p>
            <StyledProductNotesList
              data-testid="program-notes"
              dangerouslySetInnerHTML={{ __html: parsedNotes }}
            />
          </div>
        </Grid>
        <Grid>
          <Grid cols={Rights.viewAPR ? 6 : 5} tabIndex={0}>
            {rateDetails.map(
              ({
                rateDataID,
                rate,
                price,
                priceVal,
                pnI,
                apr,
                targetRow = program.rateDataID === rateDataID,
              }) => (
                <>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                    }}
                  >
                    <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                      {relockRequest.specificRateDataID === rateDataID &&
                        relockRequest.specificProgramID ===
                          program.programID && (
                          <LockIcon
                            color={["warning", "600"]}
                            title="Rate lock has been requested for this item"
                            style={{ marginRight: "8px" }}
                          />
                        )}
                      <Grid
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <StyledEligibleProgramLinks
                          onClick={() => {
                            handleSelectRow(program);
                            handleSelectProduct(program.programID, rateDataID);
                          }}
                        >
                          {dispRate(rate, priceVal)}
                        </StyledEligibleProgramLinks>
                      </Grid>
                    </StyledEligibleRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? "bold" : "",
                    }}
                  >
                    <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispPrice(price, priceVal)} />
                    </StyledEligibleRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? "bold" : "",
                    }}
                  >
                    <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispCurrencyMask(priceVal)} />
                    </StyledEligibleRowSelected>
                  </Grid>
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                      fontWeight: targetRow ? "bold" : "",
                    }}
                  >
                    <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                      <CellValue value={dispCurrencyMask(pnI)} />
                    </StyledEligibleRowSelected>
                  </Grid>
                  {Rights.viewAPR && (
                    <Grid
                      height="32px"
                      style={{
                        paddingRight: 8,
                        fontWeight: targetRow ? "bold" : "",
                      }}
                    >
                      <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                        <CellValue value={dispApr(apr)} />
                      </StyledEligibleRowSelected>
                    </Grid>
                  )}
                  <Grid
                    height="32px"
                    style={{
                      paddingRight: 8,
                    }}
                  >
                    <StyledEligibleRowSelected targetRow={targetRow} gridRow>
                      <Grid
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <StyledEligibleProgramLinks
                          onClick={() => {
                            handleSelectRow(program);
                            setSlide({
                              rowData: program,
                              rateData: rateDataID,
                              open: false,
                              data: null,
                            });
                          }}
                        >
                          details
                        </StyledEligibleProgramLinks>
                      </Grid>
                    </StyledEligibleRowSelected>
                  </Grid>
                </>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DropdownDetails.defaultProps = {
  row: {},
};

DropdownDetails.propTypes = {
  row: objectOf(),
};

export default DropdownDetails;
