import { get } from "lodash";
import browserHistory from "utils/browserHistory";
import Common from "common/services/Common";
import Rights from "common/services/Rights";
import moment from "moment";
import { PATH_LOGIN } from "routes/constants";
import { ENV_NAME } from "common/constants/AppGlobals";
import Session, {
  ORIGIN_SOURCE,
  ORIGIN_LOCKID,
  USER_TYPE,
} from "services/Session";
import { truncateText } from "utils/helpers";
import { openLoader, closeLoader } from "../screenLoader/actions";
import {
  setRelockDataSuccessAction,
  setAllRelockDataSuccessAction,
  getRatesDataSuccessAction,
  getRatesDataAction,
  resetRatesDataAction,
  resetGridDataAction,
} from "./actions";
import { setStatusFail } from "../lockSummary/actions";
import { openGlobalErrorModalAction } from "../globalErrorModal/actionCreators";
import getRelockData, {
  getRatesData,
} from "../../services/lockConfirmServices";
import { getUpdatedSummary } from "./helpers";

export const resetGridData = (gridName) => (dispatch) =>
  dispatch(resetGridDataAction(gridName));

export const resetRatesData = () => (dispatch) =>
  dispatch(resetRatesDataAction);

export const getRatesAction = (date, gridName, isCustom = false) => async (
  dispatch,
  getState
) => {
  dispatch(openLoader("Fetching Data .."));
  dispatch(resetGridData(gridName));
  try {
    const loanData = get(getState(), "EPPS.loanInfo") || {};
    const ratesData = await getRatesData(
      loanData,
      date,
      isCustom,
      dispatch,
      getRatesDataAction
    );
    if (ratesData) {
      const payload = {
        gridName,
        ratesData,
      };
      dispatch(getRatesDataSuccessAction(payload));
    }
  } catch (e) {
    openGlobalErrorModalAction(dispatch);
  } finally {
    dispatch(closeLoader());
  }
};

export const getRelockDataAction = () => async (dispatch, getState) => {
  dispatch(openLoader("Fetching Data .."));
  try {
    const loanData = get(getState(), "EPPS.loanInfo") || {};
    const lockEventsList =
      get(getState(), "EPPS.originalLoanLockRequest") || {};

    const currentDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const currentDateObj = {
      requestedStatus: "",
      requestedDate: currentDate,
      timeRequested: "",
    };
    const eventList = lockEventsList.lockRequests;
    const cloneEventList = [...eventList];
    if (cloneEventList) {
      cloneEventList.push(currentDateObj);
    }
    const user = Session.get(USER_TYPE);
    const relockData = await getRelockData(
      loanData,
      dispatch,
      setRelockDataSuccessAction,
      cloneEventList
    );
    if (relockData && relockData.code !== undefined) {
      if (user && user.userType === "StandAlone") {
        browserHistory.push(PATH_LOGIN);
      } else {
        openGlobalErrorModalAction(dispatch);
      }
    } else {
      const relockDataWithEvent = {
        eventList: cloneEventList,
        relockData,
      };
      dispatch(setAllRelockDataSuccessAction(relockDataWithEvent));
    }
  } catch (e) {
    openGlobalErrorModalAction(dispatch);
  } finally {
    dispatch(closeLoader());
  }
};

export const cancelLockAction = (comments) => async (dispatch) => {
  dispatch(openLoader("Cancelling Lock"));
  try {
    const transactionId = await Common.createTransaction({
      request: {
        type: "CANCELLOCK",
        options: {
          comments,
        },
      },
    });
    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        Common.closeApplication();
      } else if (getEpcTransaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
    dispatch(closeLoader());
  }
};

export const denyLockRequestAction = (comments) => async (dispatch) => {
  dispatch(openLoader("Denying Lock Request"));
  try {
    const lockId = Session.get(ORIGIN_LOCKID);
    const transactionId = await Common.createTransaction({
      request: {
        type: "DENYLOCK",
        options: {
          comments,
          ...(lockId ? { lockId } : {}),
        },
      },
    });
    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        Common.closeApplication();
      } else if (getEpcTransaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const denyActiveLockAction = (comments) => async (
  dispatch,
  getState
) => {
  dispatch(openLoader("Denying Lock"));
  try {
    const state = getState();
    const lockRequests = get(
      state,
      "EPPS.originalLoanInformation.lockRequests",
      []
    );
    const { id: activeLockId } = lockRequests.find(
      ({ requestedStatus }) => requestedStatus === "Locked"
    );
    const transactionId = activeLockId
      ? await Common.createTransaction({
          request: {
            type: "DENYLOCK",
            options: {
              comments,
              lockId: activeLockId,
            },
          },
        })
      : null;
    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        Common.closeApplication();
      } else if (getEpcTransaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const updateAndConfirmAction = (setOpenModal) => async (
  dispatch,
  getState
) => {
  dispatch(openLoader("Lock and confirm..."));
  try {
    const state = getState();
    const selectedPrice = get(state, "epps.lockSummary.selectedPrice");
    const hedgeCheckbox = get(state, "epps.lockSummary.isLoanHedged");
    let summarydetails;
    if (selectedPrice === "currentPrice") {
      summarydetails = get(state, "epps.lockSummary.currentRateSelector");
    } else {
      summarydetails = get(state, "epps.lockSummary.rateSelector");
    }
    if (summarydetails?.loan.hedged !== hedgeCheckbox) {
      const loanData = get(state, "EPPS.loanInfo") || {};
      summarydetails = await getUpdatedSummary(
        selectedPrice,
        loanData,
        dispatch
      );
    }
    const buysideAdjustments = Object.values(
      state.epps.origin.buySideAdjustments
    ).flat();

    const originSource = Session.get(ORIGIN_SOURCE) || "";
    const lockId = Session.get(ORIGIN_LOCKID);
    const transactionId = await Common.createTransaction({
      request: {
        type: "UPDATE_CONFIRM",
        options: {
          actionType: "",
          summarydetails,
          buysideAdjustments,
          ...(lockId ? { lockId } : {}),
          loanElements: [],
          customFields: [],
          sourceApplicationForm: originSource,
        },
      },
    });

    if (transactionId) {
      const transaction = await Common.getEpcTransaction(transactionId);
      if (transaction.status === "completed") {
        if (Rights.accessSellside) setOpenModal(true);
        else Common.closeApplication();
      } else if (transaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: transaction.code,
            message: truncateText(transaction.details, 300),
          })
        );
      } else if (
        transaction.status === "failed" &&
        transaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: transaction.code,
            message: transaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: transaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else {
      throw new Error();
    }
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export default getRelockDataAction;
