import { get, isEmpty } from "lodash";
import browserHistory from "utils/browserHistory";
import Common from "common/services/Common";
import createAction, { openLoader, closeLoader } from "utils/createAction";

import { PATH_LOGIN, PATH_SEARCH_RESULTS } from "routes/constants";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import {
  rateSelector,
  getEncompassSettings,
} from "services/lockSummaryServices";
import { getBuySideQualification } from "Qualification/QualificationActions";
import { DEBUG_BUYSIDE, ENV_NAME } from "common/constants/AppGlobals";
import { rateSelectorBuySide } from "mocks/Buyside";
import { setRedirectTabAction } from "data/landing/actions";
import { setLockedProgramData } from "data/origin/actions";
import Session, {
  ALL_SUMMARY_PARAMS,
  ORIGIN_SOURCE,
  USER_TYPE,
} from "services/Session";
import { truncateText } from "utils/helpers";
import {
  setStatusFail,
  setRateSelectorAction,
  setDiffElementsAction,
  setCurrentRateSelectorAction,
  setCompareDataAction,
  selectProduct,
  setSettings,
  SELECT_PRICE,
} from "./actions";
import {
  checkPricingStatus,
  findBestProgram,
  getAdjustmentCompare,
  getSnapshotProgramInfo,
  parseOriginAdjustments,
  parseRateAdjustmentsValidate,
} from "./helpers";

export const getRateSelector = async (dispatch, loanData) => {
  const user = Session.get(USER_TYPE);
  const results = await rateSelector(loanData);

  if (results && results.code !== undefined) {
    if (user && user.userType === "StandAlone") {
      browserHistory.push(PATH_LOGIN);
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === "PS031") &&
      results.summary &&
      results.summary.includes("EPPS90010")
    ) {
      const summaryResponse = JSON.parse(results.summary);
      dispatch(
        openGlobalErrorModalAction({
          errorMessage: summaryResponse.summary,
          onCloseCallback: () => {
            dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
          },
        })
      );
    } else if (
      results.errors &&
      results.errors.find((o) => o.code === "PS095")
    ) {
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            "Your ICE PPE session has expired. Please close ICE PPE and log back in.",
          onCloseCallback: Common.closeApplication,
        })
      );
    } else {
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: Common.closeApplication,
        })
      );
    }
  }
  return results;
};

export const getRateSelectorAction = () => async (dispatch, state) => {
  dispatch(openLoader("Loading"));
  try {
    const loanData = get(state(), "EPPS.loanInfo") || {};
    const results = await getRateSelector(dispatch, loanData);
    dispatch(setRateSelectorAction(results));
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const getValidateDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), "EPPS.loanInfo") || {};
  const originalLoanInfo = get(state(), "EPPS.originalLoanInformation") || {};
  const { relockRequest, additionalLockInfo } = loanData;
  const { specificRateDataID, specificProgramID } = relockRequest;
  const { lockRequests } = originalLoanInfo;
  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {}
  ).flat();
  const rateChange = loanFieldComparisson.find(({ id }) => id === "rate");
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader("Loading"));
    const pricing = await getBuySideQualification(
      "historical",
      loanData,
      dispatch
    );
    const {
      program: selectedProgram,
      rateDataID: newRateDataID,
      status,
    } = checkPricingStatus(
      specificProgramID,
      specificRateDataID,
      proposedRate,
      pricing,
      changedNoteRate
    );
    dispatch(
      setLockedProgramData({
        programName: selectedProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: selectedProgram?.notes?.replace(/\{.*?\}/g, "") || "",
        status,
        modalOpen: true,
      })
    );

    if (status === "eligible") {
      const newLoanData = {
        ...loanData,
        relockRequest: { ...relockRequest, specificRateDataID: newRateDataID },
      };
      const summary = DEBUG_BUYSIDE
        ? rateSelectorBuySide
        : await getRateSelector(dispatch, newLoanData);
      dispatch(setRateSelectorAction(summary));

      if (!DEBUG_BUYSIDE) {
        dispatch(selectProduct(selectedProgram));
      }
      if (
        loanFieldComparisson.length > 0 ||
        newRateDataID !== specificRateDataID
      ) {
        const originAdjustments = parseOriginAdjustments(originalLoanInfo);
        const compareResult = getAdjustmentCompare(
          originAdjustments,
          parseRateAdjustmentsValidate(
            summary.lien.adjustments,
            originAdjustments.some(
              ({ description }) => description === "L.O. Compensation"
            )
          )
        );
        dispatch(setCompareDataAction(compareResult));
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch {
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  }
};

export const getBuysideDataAction = () => async (dispatch, state) => {
  const loanData = get(state(), "EPPS.loanInfo", {});
  const lockRequests = get(
    state(),
    "EPPS.originalLoanInformation.lockRequests",
    []
  );

  const snapshotProgramName = getSnapshotProgramInfo(lockRequests);
  const { relockRequest, loanInformation, additionalLockInfo } = loanData || {};
  const { target } = loanInformation || {};
  const { specificRateDataID, specificProgramID } = relockRequest || {};
  const rate = target <= 20 ? target : null;
  const loanFieldComparisson = Object.values(
    additionalLockInfo?.lockCompareResults || {}
  ).flat();
  const rateChange = loanFieldComparisson.find(({ id }) => id === "rate");
  const { proposed: proposedRate } = rateChange || {};
  const changedNoteRate = !!proposedRate;
  try {
    dispatch(openLoader("Loading"));
    const historicalPricing = await getBuySideQualification(
      "historical",
      loanData,
      dispatch
    );

    const {
      program: historicalProgram,
      status: historicalStatus,
    } = checkPricingStatus(
      specificProgramID,
      specificRateDataID,
      proposedRate || rate,
      historicalPricing,
      changedNoteRate
    );
    dispatch(
      setLockedProgramData({
        programName: historicalProgram?.program || snapshotProgramName,
        programID: specificProgramID,
        notes: historicalProgram?.notes?.replace(/\{.*?\}/g, "") || "",
        status: historicalStatus,
        modalOpen: true,
      })
    );

    if (historicalStatus === "eligible") {
      dispatch(selectProduct(historicalProgram));
      const historicalSummary = DEBUG_BUYSIDE
        ? rateSelectorBuySide
        : await getRateSelector(dispatch, loanData);
      dispatch(setRateSelectorAction(historicalSummary));

      const { price: historicalPrice, qualifiedRate: originalRate } =
        historicalSummary?.lien || {};
      const currentPricing = await getBuySideQualification(
        "current",
        loanData,
        dispatch
      );
      const {
        program: currentProgram,
        rateDataID: newRateDataID,
        status: currentStatus,
      } = checkPricingStatus(
        specificProgramID,
        specificRateDataID,
        Number(originalRate),
        currentPricing,
        false
      );

      if (currentStatus === "eligible") {
        const { price: currentPrice } =
          currentProgram?.rateDetails?.find(
            ({ rateDataID }) => rateDataID === newRateDataID
          ) || {};
        const priceDifference =
          typeof currentPrice === "number" &&
          historicalPrice.toFixed(3) !== (100 - currentPrice).toFixed(3);
        if (priceDifference) {
          const newLoanData = {
            ...loanData,
            relockRequest: {
              ...relockRequest,
              effectiveDate: "",
              specificRateDataID: newRateDataID,
            },
          };
          const currentSummary = await getRateSelector(dispatch, newLoanData);
          dispatch(setCurrentRateSelectorAction(currentSummary));
          const compareResult = getAdjustmentCompare(
            historicalSummary?.lien?.adjustments,
            currentSummary?.lien?.adjustments
          );
          dispatch(setCompareDataAction(compareResult));
        }
      }
      dispatch(closeLoader());
    } else {
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
    }
  } catch {
    dispatch(closeLoader());
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  }
};

export const getSettingAction = () => async (dispatch) => {
  try {
    dispatch(openLoader("Loading"));
    const settings = await getEncompassSettings();
    if (settings && !settings.code)
      dispatch(setSettings(settings.adjustmentsSettings));
  } catch {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const saveLoanAction = (rateLockContract) => async (dispatch, state) => {
  dispatch(openLoader("Saving Encompass Elements"));
  try {
    const getSummarydetails = get(state(), "epps.lockSummary.rateSelector", {});
    const getEncompassElements = get(
      state(),
      "eppsData.lookups.data.encompassElements",
      []
    );
    const originalLoanInfo = get(state(), "epps.origin.originalLoanData", {});
    const validSummary = !!getEncompassElements.find(
      (element) => element.key === "SummaryQuote"
    );
    const summaryDetails = validSummary ? getSummarydetails : {};
    const getCustomFields = get(
      state(),
      "EPPS.loanInfo.lockRequestAdditionalFields",
      []
    );
    const getOriginSource = Session.get(ORIGIN_SOURCE) || "";
    const customFields = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    const transactionId = await Common.createTransaction({
      request: {
        type: "SUBMIT_LOAN",
        options: {
          actionType: "SubmitRates",
          summaryDetails,
          loanElements: [],
          customFields,
          sourceApplicationForm: getOriginSource,
        },
      },
    });

    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        await Common.refreshGetOrigin(true);
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput: {
            lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
            noClosingCostOption: rateLockContract.noClosingCost,
          },
          sourceApplicationForm: getOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        } else Common.closeApplication();
      } else if (getEpcTransaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const requestLockAction = (
  EPCActionType,
  actionType,
  rateLockContract
) => async (dispatch, state) => {
  dispatch(openLoader("Requesting Lock"));
  try {
    const summaryDetails = get(state(), "epps.lockSummary.rateSelector", {});
    const sellSideBestPrice =
      summaryDetails?.loan?.autolocked?.sellSideBestPrice;
    const originalLoanInfo = get(state(), "epps.origin.originalLoanData", {});
    const getCustomFields = get(
      state(),
      "EPPS.loanInfo.lockRequestAdditionalFields",
      []
    );
    const isLockRequestUnderOnrp = get(
      state(),
      "lockDeskStatus.isLockRequestUnderOnrp",
      {}
    );
    const onrpFields = get(state(), "EPPS.loanInfo.loanInformation", []);
    const { loanChannel, firstMortgageAmount } = onrpFields;
    const getOriginSource = Session.get(ORIGIN_SOURCE) || "";
    const customFields = [];

    if (!isEmpty(getCustomFields)) {
      getCustomFields.map((item) => {
        return customFields.push({
          fieldId: item.fieldId,
          value: item.value,
        });
      });
    }

    let sellSideBestSummary;
    if (sellSideBestPrice) {
      const { loanPrograms, loanId } = get(state(), "EPPS.loanPrograms", {});
      const loanData = get(state(), "EPPS.loanInfo", {});
      const { relockRequest } = loanData;
      const {
        rate: selectedRate,
        loanTerm: selectedTerm,
      } = summaryDetails?.lien;

      const { bestProgramID, bestRateID } = findBestProgram(
        loanPrograms,
        selectedRate,
        selectedTerm
      );
      Session.set(ALL_SUMMARY_PARAMS, {
        loanId,
        programId: bestProgramID,
        rateDataId: bestRateID,
        effectiveDate: relockRequest?.effectiveDate,
      });
      sellSideBestSummary = await getRateSelector(loanData, dispatch);
    }

    const transactionId = await Common.createTransaction({
      request: {
        type: EPCActionType,
        options: {
          actionType,
          summaryDetails,
          customFields,
          loanElements: [],
          sourceApplicationForm: getOriginSource,
          ...(sellSideBestSummary
            ? { sellSideDetails: sellSideBestSummary }
            : {}),
        },
      },
    });

    if (transactionId) {
      const getEpcTransaction = await Common.getEpcTransaction(transactionId);
      if (getEpcTransaction.status === "completed") {
        if (isLockRequestUnderOnrp) {
          await Common.getOnrpAmount(
            loanChannel === 0 ? 1 : loanChannel,
            firstMortgageAmount
          );
        }
        await Common.refreshGetOrigin(true);
        const diffELents = await Common.getUpdatedfield({
          loan: { ...originalLoanInfo },
          rateLockInput: {
            lenderFeeWaiverOption: rateLockContract.lenderFeeWaiver,
            noClosingCostOption: rateLockContract.noClosingCost,
          },
          sourceApplicationForm: getOriginSource,
        });
        if (!isEmpty(diffELents)) {
          dispatch(setDiffElementsAction(diffELents));
        } else Common.closeApplication();
      } else if (getEpcTransaction.summary === "Conflict") {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: truncateText(getEpcTransaction.details, 300),
          })
        );
      } else if (
        getEpcTransaction.status === "failed" &&
        getEpcTransaction.code === "EBS-912"
      ) {
        dispatch(
          setStatusFail({
            error: true,
            code: getEpcTransaction.code,
            message: getEpcTransaction.details,
          })
        );
      } else if (["dev", "qa", "int"].includes(ENV_NAME)) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: getEpcTransaction.summary,
            title: "Transaction Failed",
          })
        );
      }
    } else throw new Error();
  } catch (e) {
    dispatch(
      openGlobalErrorModalAction({
        onCloseCallback: Common.closeApplication,
      })
    );
  } finally {
    dispatch(closeLoader());
  }
};

export const selectPriceAction = (newSelection) => {
  return createAction(SELECT_PRICE)(newSelection);
};
